import React, { forwardRef, useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import { type VariantProps, cva } from "class-variance-authority";
import { mode } from "crypto-js";
import { ThemedLogo } from "@/components/ui/ThemedLogo";
import { ProgressDots } from "../ui/ProgressDots";
import { AnimatePresence, motion } from "framer-motion";
import { current } from "@reduxjs/toolkit";

// Define size variants for the modal
export const modalSizeVariants = cva(
  "border p-2 xs:p-3 pt-3 sm:p-3 w-full max-h-screen overflow-y-scroll rounded-lg sm:rounded-lg bg-background-light text-black shadow dark:text-white mx-auto",
  {
    variants: {
      variant: {
        error: "rounded-md bg-red-100 text-red-800 shadow dark:text-red-200",
        default: "",
      },
      size: {
        lg: "md:p-8 xl:p-8 max-w-full sm:max-w-[700px] md:max-w-[75vw] max-h-[80vh] lg:max-w-[1200px]",
        md: "px-3 xxs:px-3.5 xs:px-5 sm:p-5 sm:pb-3.5 sm:px-7 md:p-9 md:py-8 md:pb-7 max-w-[504px] max-h-[75vh]",
        sm: "sm:p-4 md:p-4 max-w-full sm:max-w-[460px] max-h-[66vh]",
        full: "lg:px-8 lg:py-6 max-h-full",
        error: "p-6 sm:max-w-lg xl:max-w-lg",
        tight: "p-0.5 sm:p-0.5 max-w-md max-h-[75vh] sm:max-w-md xl:max-w-lg border-none",
      },
      height: {
        default: "",
        nav: "top-navbar-height",
        navWithBanner: "h-full-minus-navbar-banner top-navbar-banner-height",
        blank: "h-full",
        full: "h-full justify-center max-h-full",
        screen: "h-screen overflow-scroll",
        screenScroll: "min-h-screen h-full",
      },
    },
    defaultVariants: {
      size: "md",
      variant: "default",
      height: "default",
    },
  }
);

const titleStyleVariants = cva("flex items-baseline justify-between gap-x-3 pb-2 sm:mx-auto text-darkBlue", {
  variants: {
    titleSize: {
      lg: "text-xl font-extrabold pb-1", // Larger and bold for large size
      md: "text-lg font-bold pb-1", // Medium size with medium boldness
      sm: "text-base font-normal pb-0", // Smaller and normal font weight for small size
    },
  },
  defaultVariants: {
    titleSize: "lg",
  },
});

export type ModalSizeVariantProps = VariantProps<typeof modalSizeVariants>;

interface ModalLayoutProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof modalSizeVariants>, VariantProps<typeof titleStyleVariants> {
  className?: string;
  titleClassName?: string;
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
  logo?: boolean | "light" | "dark";
  numSteps?: number;
  currentStep?: number;
  titleNoWrap?: boolean;
  subtitleBelow?: boolean;
}

export const ModalLayout = forwardRef<HTMLDivElement, ModalLayoutProps>(
  (
    {
      className,
      titleClassName,
      titleNoWrap = false,
      subtitleBelow = false,
      title,
      subtitle,
      children,
      size,
      height,
      variant,
      titleSize,
      style,
      logo,
      numSteps,
      currentStep,
      ...props
    },
    ref
  ) => {
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [prevCurrentStep, setPrevCurrentStep] = useState(0);

    useEffect(() => {
      if (currentStep) {
        setPrevCurrentStep(currentStep);
      }
    }, [currentStep]);

    useEffect(() => {
      setIsFirstRender(false);
    }, []);

    return (
      <>
        {logo && (
          <div className="mb-[69px] flex items-center justify-center">
            <ThemedLogo force={typeof logo === "string" ? logo : "dark"} width={290} className="flex h-auto max-w-full p-1" />
          </div>
        )}
        <div ref={ref} className={cn(modalSizeVariants({ size, variant, height }), className)} style={{ ...style }} {...props}>
          {(title || subtitle) && (
            <div
              className={cn(
                titleStyleVariants({ titleSize }),
                numSteps && "pb-0",
                titleNoWrap && "whitespace-nowrap",
                subtitleBelow && "flex flex-col",
                titleClassName
              )}
            >
              <AnimatePresence mode="wait">
                {title && (
                  <motion.div
                    key={title}
                    className="w-full text-center"
                    initial={{ opacity: 0, x: isFirstRender ? -20 : 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{
                      opacity: 0,
                      x: currentStep && currentStep < prevCurrentStep ? -20 : 20,
                    }}
                    transition={{ duration: 0.2 }}
                  >
                    <h2>{title}</h2>
                  </motion.div>
                )}
              </AnimatePresence>
              {subtitle && (
                <p className={cn(" text-sm font-medium text-gray-400", subtitleBelow ? "px-0 pt-3 text-center leading-normal md:px-8" : "truncate")}>
                  {subtitle}
                </p>
              )}
            </div>
          )}
          {numSteps && <ProgressDots quantity={numSteps} current={currentStep} className="mb-1.5 py-5" />}
          {children}
        </div>
      </>
    );
  }
);

ModalLayout.displayName = "ModalLayout";
